import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import styles from './Card.module.css'
import Orders from './Orders'
import delivery from '../../../img/main/icon/delivery.png'
const Card = ({ data, setVisibleModal, setModalData, sendRequest, modalData, sendStatus, pathname }) => {
  const { id, tablename, sent, orders } = data
  const [filteredOrders, setFilteredOrders] = useState([])
  const [comment, setComment] = useState([]);
  const [popupComment, setPopupComment] = useState(false);
  
  useEffect(() => {
    const newOrders = [];
    orders.forEach((order, i) => {
      let indexElement = newOrders.findIndex(el => {
        return (
          el?.menu_id === order.menu_id &&
          ((el.finished !== null && order.finished !== null) || (el.finished === null && order.finished === null))
        )
      })

      if (indexElement !== -1) {
        newOrders[indexElement].amount += 1
        newOrders[indexElement].arrayIds.push(order.id)
      } else {
        newOrders[i] = order
        newOrders[i].arrayIds = []
        newOrders[i].arrayIds.push(newOrders[i].id)
      }
    })
    newOrders.sort((a, b) => a.bl_category_id - b.bl_category_id)

    setFilteredOrders(newOrders)
  }, [orders])

  useEffect(() => {
    const newComment = orders.filter(el=> el.orderrowcomment).map(order => {
      return {
        id: order.id,
        title: order.title_ru,
        comment: order.orderrowcomment
      }
    });

    setComment(newComment);
  }, [orders])

  const now = moment(new Date())
  const sentUTC = moment.utc(sent).local()
  let diffTime = moment.duration(now.diff(sentUTC))

  const [timerData, setTimerData] = useState(diffTime ? diffTime : false)

  const durationMinutes = timerData ? Math.floor(timerData.asMinutes()) : null

  useEffect(() => {
    const timer = setInterval(() => {
      if (timerData.__proto__.hasOwnProperty('asSeconds') && timerData.asSeconds() > 0) {
        setTimerData(moment.duration(timerData.asSeconds() + 1, 'seconds'))
      } else {
        setTimerData('0:00')
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [timerData])

  return (
    <div className={styles.cardWrapper}>
      { popupComment ?
        <div className={styles.commentWrapper}>
          {comment.length > 0 ? 
            <div>
              {comment.map((el, i) => 
                <div 
                  className={styles.commentItem} 
                  key={i}>
                    <span className={styles.commentNumber}>{i+1}</span> {el.title}: {el.comment}
                </div>
                )}
            </div> : <></>}
        </div> : <></>}
      <div className={styles.card}>
      <div
        className={classNames([
          timerData.__proto__.hasOwnProperty('asSeconds')
            ? durationMinutes > 30
              ? 'card_header danger'
              : durationMinutes < 30 && durationMinutes > 15
              ? 'card_header warning'
              : 'card_header primary'
            : 'card_header primary',
        ])}
      >
        <div className={styles.card_info}>
          <span className={styles.table_guest}>Стол {tablename} &nbsp;<div className={styles.guest}> {data.guests[data.guests.length - 1].name}</div></span>
          <span>Заказ № {id}</span>
          <div className={styles.card_info_block}>
            <span>{moment(sent).format('HH:mm')}</span>
            {
              tablename === 'С собой' && <img className={styles.delivery} src={delivery} alt='delivery' /> 
            }
            {comment.length > 0 ? <span className={styles.comment} onClick={() => setPopupComment(!popupComment)}>{popupComment ? 'Скрыть' : 'Комментарии'}</span> : <></>}
          </div>
        </div>
        <div className={styles.card_time}>
          <span>
            {timerData.__proto__.hasOwnProperty('asSeconds')
              ? `${timerData.hours() < 9 ? '0' + timerData.hours() : timerData.hours()}:${
                  Math.floor(timerData.minutes()) <= 9 && timerData.minutes() < 60
                    ? '0' + timerData.minutes()
                    : timerData.minutes()
                }`
              : '0:00'}
          </span>
        </div>
      </div>
      <div className={styles.card_content}>
        
        {filteredOrders.map((item, index) => (
          <Orders 
            data={item} 
            key={`orders_${index}`} 
            setModalData={setModalData} 
            setVisibleModal={setVisibleModal} 
            sendRequest={sendRequest} 
            modalData={modalData} 
            sendStatus={sendStatus} 
            pathname={pathname}/>
        ))}
      </div>
    </div>
    </div>
  )
}

Card.propTypes = {}

export default Card
